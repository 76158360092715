<template>
	<div>
		<h2>{{ $t("tiers.menu_groupe") }}</h2>
		<div class="box">

			<CustomTable
				id_table="tiers_group"
				ref="tiers_group"
				:items="tiers_group"
				:busy.sync="table_busy"
				primaryKey="tiers_id"
				:hrefsRoutes="config_table_hrefs"
				:externSlotColumns="extern_slot_columns"
			>
				<template v-slot:[`custom-slot-cell(tiers_archive)`]="{ data }">
					<span v-if="data.tiers_archive">{{ $t('global.oui') }}</span>
					<span v-else>{{ $t('global.non') }}</span>
				</template>
				<template v-slot:[`custom-slot-cell(principal)`]="{ data }">
					<span v-if="data.first">{{ $t('global.oui') }}</span>
					<span v-else>{{ $t('global.non') }}</span>
				</template>
			</CustomTable>
		</div>

		<b-modal ref="modal" size="lg" hide-footer>
			<template v-slot:modal-title>
					{{ $t("tiers.ajouter_groupe") }}
			</template>
			
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>

			<div class="form-group" v-if="tiers_principal">
				{{ $t('tiers.tiers_principal_groupe') }} : {{ tiers_principal.tiers_rs }}
			</div>
			<div class="form-group" v-else>
				{{ $t('tiers.tiers_principal_sera_selectionne') }}
			</div>

			<div class="form-group">
				<label for="tiers" class="col-form-label">{{ $t("tiers.tier") }} *</label>
				<SearchTiers 
					:tiers_selected.sync="tiers_groupe"
					:preselected="tiers_groupe"
				/>
			</div>

			
			<b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
		</b-modal>

		<b-modal ref="modalFusion" hide-footer>
			<template v-slot:modal-title>
				{{ $t("tiers.fusionner") }}
			</template>
			
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>

			{{ tiers_from.tiers_rs }} {{ $t('tiers.vers') }} {{ tiers_to.tiers_rs }}

			
			<b-button class="mt-3 btn-block rounded-pill" block @click.prevent="saveFusion" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.fusionner") }}</b-button>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import ContactMixin from '@/mixins/Contact.js'
	import FonctionMixin from '@/mixins/Fonction.js'
	import Navigation from '@/mixins/Navigation.js'
	import TiersMixin from '@/mixins/Tiers.js'

	export default {
		name: "TiersContact",
		mixins: [ContactMixin, FonctionMixin, Navigation, TiersMixin],
		props: ['tiers_id'],
		data() {
			return {
				form_message: '',
				processing: false,
				tiers_principal: null,
				tiers_from: {},
				tiers_to: {},
				tiers_relations: null,
				table_busy: true,
				tiersrelations_id: null,
				tiers_groupe: null,
				tiers_group: [],
				config_table_hrefs: {
					'tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'tiers_id'
						}
					}
				},
				events_tab: {
                    'TableAction::goToAddTiersGroup': () => {
						this.addTiersGroup()
                    },
					'TableAction::goToDeleteTiersGroup': (tiers_ids) => {
                        this.deleteTiersGroup(tiers_ids)
                    },
					'TableAction::goToFusionTiers': (tiers_ids) => {
                        this.fusionTiersGroup(tiers_ids[0])
                    }
                },
				extern_slot_columns: ['tiers_archive', 'principal'],
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy = true
				this.tiers_group = await this.loadTiersGroup(this.tiers_id)
				this.table_busy = false
			},

			async addTiersGroup(){
				this.tiers_principal = this.tiers_group.find(elem => elem.first)
				this.$refs["modal"].show()
			},
			async checkForm(){
				if(!this.processing) {
					this.processing = true
					await this.saveTiersGroup(this.tiers_groupe.tiers_id, this.tiers_id)
					this.$refs["modal"].hide()
					this.processing = false
					await this.init_component()
				}
			},
			async deleteTiersGroup(tiers_id){
				await this.saveDeleteTiersGroup(tiers_id)
				this.$refs.tiers_group.unselectAll()
				EventBus.$emit('TableAction::stopSpin')
				await this.init_component()
			},
			async fusionTiersGroup(tiers_id) {
				this.tiers_from = this.tiers_group.find(elem => elem.tiers_id == this.tiers_id)
				this.tiers_to = this.tiers_group.find(elem => elem.tiers_id == tiers_id)
				this.$refs["modalFusion"].show()
			},

			async saveFusion() {
				if(!this.processing)
				{
					this.processing = true
					let retour = await this.fusionTiers(this.tiers_id, this.tiers_to.tiers_id)
					if(retour) {
						this.successToast()
					}
					this.$refs["modalFusion"].hide()
					this.processing = false
					await this.init_component()
				}
			}
			
		},
		computed: {
			
		},
		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
		}
	}
</script>
